import gql from "graphql-tag";

// Queries

export const GET_ONLINE_TAKEBACK_BRANDS = gql`
  query GetBrands {
    onlineTakebackBrands {
      slug
    }
  }
`;

export const GET_INSTORE_TAKEBACK_BRANDS = gql`
  query GetInstoreBrands {
    instoreTakebackBrands {
      slug
    }
  }
`;

export const GET_BRAND_ONLINE = gql`
  query GetBrand(
    $slug: String!
    $headerImageSizes: [String!]!
    $mobileHeaderImageSize: String!
  ) {
    brand(slug: $slug) {
      metaTitle
      metaDescription
      name
      logo
      live
      holdingPageHeading
      holdingPageContent
      headerTextMain
      headerTextSub
      headerImageUrls(sizes: $headerImageSizes)
      mobileHeaderImageUrl(size: $mobileHeaderImageSize)
      headerTextTheme
      headerBackgroundColour
      emailInputLabel
      incentiveType
      minItems
      maxDiscount
      discountCodeType
      stepOneHeadingOverride
      stepFourCopyOverride
      howItWorksDescription
      takebackDescription
      takebackRules
      fixedValueAmount
      fixedPercentageAmount
      items {
        id
        name
        weight
        value
        icon
        category {
          name
          priority
          getReasons
        }
        description
      }
      acceptsNonBrandItems
      donationItems {
        id
        name
        weight
        icon
      }
      collectAgeAndGender
    }
  }
`;

export const GET_BRAND_INSTORE = gql`
  query GetBrandInstore($slug: String!) {
    brandInstore(slug: $slug) {
      name
      slug
      logo
      live
      storePins
      holdingPageHeading
      holdingPageContent
      headerTextMain
      headerTextSub
      headerTextTheme
      headerBackgroundColour
      emailInputLabel
      incentiveType
      minItems
      maxDiscount
      discountCodeType
      fixedValueAmount
      fixedPercentageAmount
      items {
        id
        name
        weight
        value
        icon
        category {
          name
          priority
        }
        description
      }
      acceptsNonBrandItems
      donationItems {
        id
        name
        weight
        icon
      }
      collectAgeAndGender
    }
  }
`;

export const GET_TAKEBACK = gql`
  query GetTakeback($id: ID!, $slug: String!) {
    takeback(id: $id, slug: $slug) {
      pocketMessage
      approvalStatus
      approvedItemsValue
      brand {
        name
        logo
        incentiveType
        maxDiscount
        fixedValueAmount
        fixedPercentageAmount
        minItems
        headerTextTheme
        headerBackgroundColour
        takebackRules
        discountCodeDescription
        acceptsPocketMessage
        pocketMessageCopy
        reskinnedCta
        brandCta
      }
      takebackItems {
        id
        name
        value
        category {
          name
        }
        status
      }
      donatedTakebackItems {
        id
        name
      }
      approvedItemsValue
      discountCodes {
        code
        value
        percentage
      }
      shipment {
        labelLink
        labelType
        qrCodeUrl
        courierService {
          name
          storeFinderUrl
          storeName
        }
      }
    }
  }
`;

export const AVAILABLE_DROP_OFF_SERVICES = gql`
  query GetAvailableDropOffServices($postcode: String!) {
    availableDropOffServices(postcode: $postcode) {
      __typename
      ... on AvailableDropOffServices {
        dropOffServices {
          courierServiceId
          courierServiceName
          labelType
          distance
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

// Mutations

export const CREATE_TAKEBACK = gql`
  mutation CreateTakeback(
    $email: String!
    $mobileNumber: String!
    $optOutReskinned: Boolean!
    $optInBrand: Boolean!
    $courierServiceId: String!
    $labelType: String!
    $takebackItems: [TakebackItemInput!]!
    $donationItemIds: [String!]!
    $ageGroup: String
    $gender: String
    $postcode: String!
  ) {
    createTakeback(
      email: $email
      mobileNumber: $mobileNumber
      optOutReskinned: $optOutReskinned
      optInBrand: $optInBrand
      courierServiceId: $courierServiceId
      labelType: $labelType
      takebackItems: $takebackItems
      donationItemIds: $donationItemIds
      ageGroup: $ageGroup
      gender: $gender
      postcode: $postcode
    )
  }
`;

export const ADD_POCKET_MESSAGE = gql`
  mutation AddPocketMessage($takebackId: String!, $pocketMessage: String!) {
    addPocketMessage(takebackId: $takebackId, pocketMessage: $pocketMessage)
  }
`;

export const CREATE_INSTORE_TAKEBACK = gql`
  mutation CreateInstoreTakeback(
    $email: String!
    $numberOfItems: String!
    $brandSlug: String!
    $optOutReskinned: Boolean!
    $optInBrand: Boolean!
    $storePin: String!
    $brandItemIds: [String!]!
    $donationItemIds: [String!]!
  ) {
    createInstoreTakeback(
      email: $email
      numberOfItems: $numberOfItems
      brandSlug: $brandSlug
      optOutReskinned: $optOutReskinned
      optInBrand: $optInBrand
      storePin: $storePin
      brandItemIds: $brandItemIds
      donationItemIds: $donationItemIds
    )
  }
`;
